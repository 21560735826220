<template>
  <div class="talent-recruitment-page">
    <div class="recruit">
      <div class="r-head">
        <ul>
          <li class="w1">
            <h6 class="c01">招聘职位</h6>
          </li>
          <li class="w2">
            <h6 class="c02">招聘地区</h6>
          </li>
          <li class="w3">
            <h6 class="c03">招聘人数</h6>
          </li>
          <li class="w4">
            <h6 class="c04">发布时间</h6>
          </li>
          <li class="w5">
            <h6 class="c05">投递简历</h6>
          </li>
        </ul>
      </div>
      <div class="r-list" v-for="(item, index) in recruitList" :key="index">
        <ul>
          <li class="w1">{{ item.jobTitle }}</li>
          <li class="w2">{{ item.address }}</li>
          <li class="w3">{{ item.count }}</li>
          <li class="w4">{{ item.releaseDate.split(" ")[0] }}</li>
          <li class="w5">
            <span class="more" @click="() => goThere('recruitInfo', item.jobId)"
              >more</span
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="m-page">
      <!-- <Page
        :current="page.pageNo"
        :page-size="page.pageSize"
        :total="page.total"
        @on-change="pageChange"
      ></Page> -->
      <page-view :page="page" @pageChange="pageChange"></page-view>
    </div>
  </div>
</template>

<script>
import pageView from "@components/pageView";
export default {
  name: "",
  components: {
    pageView,
  },
  data() {
    return {
      recruitList: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  computed: {
    getSubMenu() {
      return this.$store.state.permission.subMenu;
    },
    getCurrentRoute() {
      return this.$store.state.permission.currentRoute;
    },
  },
  methods: {
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    getList() {
      let menu = this.getSubMenu.childList.filter(
        (item) => item.router === this.getCurrentRoute
      )[0];
      if (menu.Interface) {
        this.$post(menu.Interface, {
          pageNo: this.page.pageNo,
          pageSize: this.page.pageSize,
        }).then((res) => {
          // console.log(res);
          this.recruitList = res.list;
          this.page.total = +res.total;
        });
      }
    },
    goThere(router, jobId) {
      this.$router.push({ name: router, params: { jobId } });
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.talent-recruitment-page {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 40px;
  .recruit {
    width: 100%;
    margin-top: 50px;
    .r-head {
      height: 50px;
      background: #245f5c;
      ul {
        display: flex;
        li {
          text-align: center;
          line-height: 50px;
          h6 {
            display: inline-block;
            color: #fff;
            font-size: 16px;
            padding-left: 32px;
            font-weight: normal;
          }
          .c01 {
            background: url("../../../../public/imgs/or/or01.png") 8px center
              no-repeat;
          }
          .c02 {
            background: url("../../../../public/imgs/or/or02.png") 3px center
              no-repeat;
          }
          .c03 {
            background: url("../../../../public/imgs/or/or03.png") left center
              no-repeat;
          }
          .c04 {
            background: url("../../../../public/imgs/or/or04.png") 2px center
              no-repeat;
          }
          .c05 {
            background: url("../../../../public/imgs/or/or05.png") 2px center
              no-repeat;
          }
        }
      }
    }
    .r-list {
      height: 60px;
      line-height: 60px;
      border-bottom: 1px #c9c9c9 dotted;
      ul {
        display: flex;
        li {
          text-align: center;
          .more {
            display: inline-block;
            width: 91px;
            height: 26px;
            line-height: 26px;
            border-radius: 26px;
            text-align: center;
            background: #245f5c;
            color: #fff;
            cursor: pointer;
            &:hover {
              background: #ff9c00;
            }
          }
        }
      }
    }
    .w1 {
      width: 250px;
    }
    .w2 {
      width: 215px;
    }
    .w3 {
      width: 280px;
    }
    .w4 {
      width: 230px;
    }
    .w5 {
      width: 225px;
    }
  }
  .m-page {
    text-align: center;
    margin-top: 40px;
  }
}
</style>